.offerPlan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: 'transparent';
    border-width: 3px;
    border-style: solid;
    min-width: 320px;
}
    .offerPlan-container-additionalDataRC {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
        .offerPlan-container-additionalDataRC-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
        }
            .offerPlan-container-additionalDataRC-title-text {
                color: var(--primary500);   
                margin-left: 5px;
            }
        .offerPlan-container-additionalDataRC-text {
            color: var(--gray600);
            text-align: center;
        }
    .offerPlan-container-title {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px 25px 0 0;
        width: 100%;
        padding: 10px 0;
    }
        .offerPlan-container-title-text {
            color: var(--gray600);
        }
    .offerPlan-container-score {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }
    .offerPlan-container-planType {
        color: var(--gray400);
    }
    .offerPlan-container-price {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 8px;
        flex-direction: row;
        width: 100%;
    }
        .offerPlan-container-price-unit {
            color: var(--gray400);
            margin-bottom: 7px;
        }
        .offerPlan-container-price-price {
            color: var(--primary500);
        }
        .offerPlan-container-price-per {
            color: var(--gray400);
            margin-bottom: 9px;
        }
    .offerPlan-container-line {
        width: 100%;
        height: 1px;
        background-color: var(--gray200);
        margin: 16px 0;
    }
    .offerPlan-container-queCubre {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 90%;
    }
        .offerPlan-container-queCubre-title {
            color: var(--gray400);
            margin-bottom: 12px;
        }
        .offerPlan-container-queCubre-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
            .offerPlan-container-queCubre-list-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;
                width: 100%;
            }
                .offerPlan-container-queCubre-list-item-title {
                    color: var(--gray600);
                    text-align: center;
                    width: 100%;
                }
                .offerPlan-container-queCubre-list-item-descripcion {
                    color: var(--primary500);
                    text-align: center;
                }
    .offerPlan-container-boton {
        width: 90%;
        margin: 0px auto;
        margin-bottom: 10px;
    }
    .offerPlan-container-discount {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
        .offerPlan-container-discount-plan {
            display: flex;
            flex-direction: row;
        }
            .offerPlan-container-discount-plan-title {
                color: var(--gray400);
            }
            .offerPlan-container-discount-plan-discount {
                color: var(--secondary400);
            }
        .offerPlan-container-discount-price {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: row;
            width: 100%;
        }
            .offerPlan-container-discount-price-unit {
                color: var(--gray400);
                margin-bottom: 7px;
            }
            .offerPlan-container-discount-price-price {
                color: var(--secondary500);
            }
            .offerPlan-container-discount-price-per {
                color: var(--gray400);
                margin-bottom: 9px;
            }
        .offerPlan-container-discount-discountedPrice {
            text-decoration: line-through;
            color: var(--primary500);
        }