.categoriaButton-container {
    display: flex;
    height: 264px;
    min-width: 264px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary200);
    transition: all 0.25s ease-in;
    cursor: pointer;
    position: relative;
}
    @media only screen and (max-width: 37.5em) {
        .categoriaButton-container {
        }}
    .categoriaButton-container:active {
        transform: scale(0.95);
    }
    .categoriaButton-container-img {
        height: 100%;
        width: 100%;
    }
    .categoriaButton-container-tag {
        position: absolute;
        color: var(--white);
        background-color: var(--secondary500);
        border-radius: 5px;
        padding: 5px 8px;
        top: 10px;
        right: 12px;
    }