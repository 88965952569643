.verMasModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .verMasModal-container-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .verMasModal-container-title {
        color: var(--gray700);
    }
    .verMasModal-container-data {
        display: flex;
        flex-direction: column;
        margin: 16px 0;
        margin-right: auto;
    }
        .verMasModal-container-data-title {
            color: var(--gray500);
            margin-bottom: 10px;
        }
        .verMasModal-container-data-text {
            color: var(--gray500);
        }
    .verMasModal-container-close {
        margin-left: auto;
        cursor: pointer;
    }
    .verMasModal-container-boton {
        width: 20%;
        cursor: pointer;
    }
        @media only screen and (max-width: 37.5em) {
            .verMasModal-container-boton {
                width: 80%;
            }}